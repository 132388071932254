<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col cols="12" md="4" class="py-0">
        <v-select 
          v-model="type"
          :items="types" 
          dense
          outlined
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="2" lg="1" class="pa-0">
        <v-btn
          icon
          @click="$refs.calendar.prev()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="8" lg="6" class="pa-0" align="center">
        {{ getDate }}
      </v-col>
      <v-col cols="2" lg="1" class="pa-0" align="end">
        <v-btn
          icon
          @click="$refs.calendar.next()"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" lg="8">
        <v-sheet height="600" class="overflow-x-auto">
          <v-calendar
            ref="calendar"
            v-model="date"
            color="primary"
            :type="type"
            :events="events"
            event-color="primary"
            @change="getMonthAndYear"
            :class="$vuetify.breakpoint.xs ? 'width-200' : ''"
          >
            <template #event="event">
              <span class="pl-1">{{ event.timeSummary() }}</span>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="7" lg="4">
        <v-card min-height="250">
          <v-card-title class="primary text-h4 white--text pa-6">
            {{ getDateCard }}
          </v-card-title>
          <v-card-text class="pa-2">
            <v-card v-if="this.date && adminProfile.role_id != 3" class="mb-4 py-1">
              <v-card-title>
                <span class="text-body-2 font-weight-bold">ADD NEW SLOT</span>
                <v-spacer></v-spacer>
                <v-icon color="primary" @click="addSlotDialog">
                  mdi-plus-circle-outline
                </v-icon>
              </v-card-title>
            </v-card>
            <v-card 
              v-for="slot in daySlots"
              :key="slot.id"
              class="py-1"
            >
              <v-card-title class="text-body-2 font-weight-bold">
                <span>{{ slot.slot_time }}</span>
                <v-spacer></v-spacer>
                <span :class="slot.is_full ? 'red--text': 'green--text'">{{ slot.booking_count }} / {{ slot.capacity }}</span>
                <v-spacer></v-spacer>
                <v-icon color="primary" @click="updateSlotDialog(slot)" v-if="adminProfile.role_id != 3">
                  mdi-pencil
                </v-icon>
              </v-card-title>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="slotDialog"
      :width="$vuetify.breakpoint.xs?'90%':'400'"
      persistent
    >
      <v-card>
        <v-container class="pa-0">
          <v-card-title>
            {{ slotTitle }}
            <v-spacer></v-spacer>
            <span v-if="!create" class="font-weight-bold" :class="currentSlot.is_full ? 'red--text': 'green--text'">
              {{ currentSlot.booking_count }} / {{ currentSlot.capacity }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="slotForm" @submit.prevent="onSubmit()">
              <v-row>
                <v-col cols="12" align="center">
                  <span class="font-weight-bold text-h6">
                    {{ new Date(this.date).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' }) }}
                  </span>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col>
                  Slot Capacity
                </v-col>
                <v-col align="end">
                  <v-btn 
                    color="primary"
                    icon
                    @click="editedSlot.capacity--"
                    :disabled="editedSlot.capacity==1 || editedSlot.capacity <= currentSlot.booking_count"
                  >
                    <v-icon>mdi-minus-circle-outline</v-icon>
                  </v-btn>
                  <span class="font-weight-bold">{{ editedSlot.capacity }}</span>
                  <v-btn 
                    color="primary"
                    icon
                    @click="editedSlot.capacity++"
                  >
                    <v-icon>mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="5" class="py-0">
                  <v-text-field
                    v-model="editedSlot.start_time"
                    type="time"
                    outlined
                    dense
                    :disabled="currentSlot.booking_count > 0"
                    @change="$refs.slotForm.validate()"
                    :rules="startTimeRules"
                    color="primary"
                  ></v-text-field>
                </v-col>
                <v-col align="center" class="mb-7">
                  to
                </v-col>
                <v-col cols="5" class="py-0">
                  <v-text-field
                    v-model="editedSlot.end_time"
                    type="time"
                    outlined
                    dense
                    :disabled="currentSlot.booking_count > 0"
                    @change="$refs.slotForm.validate()"
                    :rules="endTimeRules"
                    color="primary"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn
                    depressed 
                    block
                    class="mb-5 primary-border"
                    @click="closeSlotDialog"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    block
                    color="primary"
                    :class="create ? '' : 'mb-5'"
                    type="submit"
                    :loading="loading"
                    :disabled="create ? !valid : editedSlot.capacity < currentSlot.booking_count || slotUnchanged()"
                  >
                    {{ create ? 'Add': 'Update'}}
                  </v-btn>
                  <template v-if="!create">
                    <v-divider class="mb-5"></v-divider>
                    <v-btn
                      depressed 
                      block
                      color="red"
                      class="white--text"
                      :disabled="currentSlot.booking_count > 0"
                      @click="confirmDeleteSlot()"
                    >
                      Delete
                    </v-btn> 
                  </template>
                </v-col>                
              </v-row>
            </v-form>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmationDialog"
      :width="$vuetify.breakpoint.xs?'90%':'400'"
      persistent
    >
      <v-card>
        <v-container class="pa-0">
          <v-card-title>
            {{ confirmationTitle }}
          </v-card-title>
          <v-card-text>
            <v-simple-table v-if="!deletion">
              <thead class="primary">
                <tr>
                  <th class="text-center">
                  </th>
                  <th class="text-center white--text">
                    Capacity
                  </th>
                  <th class="text-center white--text">
                    Start Time
                  </th>
                  <th class="text-center white--text">
                    End Time
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td>
                    Before
                  </td>
                  <td>{{ currentSlot.capacity }}</td>
                  <td>{{ currentSlot.start_time }}</td>
                  <td>{{ currentSlot.end_time }}</td>
                </tr>
                <tr class="text-center font-weight-bold">
                  <td>
                    After
                  </td>
                  <td>{{ editedSlot.capacity }}</td>
                  <td>{{ editedSlot.start_time }}</td>
                  <td>{{ editedSlot.end_time }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-row>
              <v-col>
                <v-btn
                  depressed
                  block
                  color="green"
                  class="white--text"
                  :loading="confirmationLoading"
                  @click="deletion ? deleteSlot(currentSlot.id) : updateSlot(currentSlot.id)"
                >
                  Yes
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  depressed
                  block
                  class="primary-border"
                  @click="closeConfirmation()"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiService from '@/services/api.service.js'

export default {
  data() {
    return {
      adminProfile: {},
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      events: [],
      slots: [],
      daySlots: [],
      timeSlots: [],
      slotDates: [],
      currentMonth: '',
      currentYear: '',
      date: '',
      currentSlot: {},
      defaultSlot: {
        id: '',
        capacity: 10,
        start_time: '09:00',
        end_time: '10:00',
      },
      editedSlot: {
        id: '',
        capacity: 10,
        start_time: '09:00',
        end_time: '10:00',
      },
      loading: false,
      confirmationLoading: false,
      slotDialog: false,
      confirmationDialog: false,
      create: false,
      deletion: false,
      valid: false,
      startTimeRules: [
        value => this.convertTime(value)<this.convertTime(this.editedSlot.end_time) || 'Cannot be later than end time'
      ],
      endTimeRules: [
        value => this.convertTime(value)>this.convertTime(this.editedSlot.start_time) || 'Cannot be earlier than start time'
      ]
    }
  },
  methods: {
    async fetchSlots() {
      this.slots = []
      try {
        const res = await apiService.get('api/v1/admin/slots', { 
          year: this.currentYear,
          month: this.currentMonth
        })
        const response = res.data
        this.slots = response.data
        const events = this.slots.map(({ slot_date, slot_time, is_full }) => ({
          name: '',
          start: `${this.formatDate(slot_date, '/', '-')} ${slot_time.split(' - ')[0]}`,
          end: `${this.formatDate(slot_date, '/', '-')} ${slot_time.split(' - ')[1]}`,
          color: is_full ? 'red' : 'green'
        }));
        this.events = events
        this.slotDates = [...new Set(this.slots.map(slot => slot.slot_date))]
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the slots at this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    async fetchDaySlots(day) {
      this.daySlots = []
      try {
        const res = await apiService.get('api/v1/admin/slots/1', { 
          date: day
        })
        const response = res.data
        this.daySlots = response.data
      }
      catch(error) {
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue retrieving the slots at this moment. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    async addSlot() {
      this.loading = true
      const slot = {
        slot_date: this.date,
        capacity: this.editedSlot.capacity,
        start_time: this.editedSlot.start_time,
        end_time: this.editedSlot.end_time
      }
      try {
        const res = await apiService.create('api/v1/admin/slots', slot)
        this.loading = false
        const response = res.data
        this.$store.dispatch('showSnackbar', 
          { 
            content: response.data.message || `Successfully created a slot!`, 
            status: 'success' 
          })
        this.fetchSlots()
        this.fetchDaySlots(this.date)
        this.closeSlotDialog()
      }
      catch(error) {
        this.loading = false
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue adding the slot. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    async updateSlot(id) {
      this.confirmationLoading = true
      const slot = {
        capacity: this.editedSlot.capacity,
        start_time: this.editedSlot.start_time,
        end_time: this.editedSlot.end_time
      }
      try {
        const res = await apiService.update(`api/v1/admin/slots/${id}`, slot)
        this.confirmationLoading = false
        const response = res.data
        this.$store.dispatch('showSnackbar', 
          { 
            content: response.data.message || 'Successfully updated the slot!', 
            status: 'success' 
          })
        this.closeConfirmation()
        this.fetchSlots()
        this.fetchDaySlots(this.date)
      }
      catch(error) {
        this.confirmationLoading = false
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue updating the slot. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    async deleteSlot(id) {
      this.confirmationLoading = true
      try {
        const res = await apiService.delete(`api/v1/admin/slots/${id}`)
        this.confirmationLoading = false
        const response = res.data
        this.$store.dispatch('showSnackbar', 
          { 
            content: response.data.message || 'Successfully deleted the slot!', 
            status: 'success' 
          })
        this.closeConfirmation()
        this.fetchSlots()
        this.fetchDaySlots(this.date)
      }
      catch(error) {
        this.confirmationLoading = false
        this.$store.dispatch('showSnackbar', 
          { 
            content: error.response.data.message || 'There was an issue deleting the slot. Please try again at a later time', 
            status: 'error' 
          })
      }
    },
    addSlotDialog() {
      this.create = true
      this.slotDialog = true
    },
    updateSlotDialog(slot) {
      this.currentSlot = slot
      this.create = false
      this.editedSlot = Object.assign({}, slot)
      this.slotDialog = true
    },
    closeSlotDialog() {
      this.slotDialog = false
      this.create = false
      this.deletion = false
      this.currentSlot = {}
      this.$nextTick(() => {
        this.editedSlot = Object.assign({}, this.defaultSlot)
        
      })
    },
    confirmUpdateSlot() {
      this.slotDialog = false
      this.confirmationDialog = true
    },
    confirmDeleteSlot() {
      this.slotDialog = false
      this.deletion = true
      this.confirmationDialog = true
    },
    closeConfirmation() {
      this.confirmationDialog = false
      this.closeSlotDialog()
    },
    getMonthAndYear(e) {
      this.currentMonth = e.start.month
      this.currentYear = e.start.year
      this.fetchSlots()
    },
    formatDate(date, oldSeparator, newSeparator) {
      const [day, month, year] = date.split(oldSeparator)

      return `${year}${newSeparator}${month}${newSeparator}${day}`
    },
    convertTime(time) {
      let [hour, min] = time.split(':')

      return hour * 60 + min
    },
    slotUnchanged() {
      if (this.create) return false
      return this.editedSlot.capacity == this.currentSlot.capacity && this.editedSlot.start_time == this.currentSlot.start_time && this.editedSlot.end_time == this.currentSlot.end_time
    },
    onSubmit() {
      this.create ? this.addSlot() : this.confirmUpdateSlot()
    },
    getAdminProfile() {
      this.adminProfile = JSON.parse(localStorage.getItem('adminAuth'))
    },
  },
  mounted() {
    this.getAdminProfile()
  },
  created() {
    this.currentMonth = new Date(Date.now()).getMonth() + 1
    this.currentYear = new Date(Date.now()).getFullYear()
    this.fetchSlots()
  },
  computed: {
    formattedDate() {
      return new Date(this.date).toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric' })
    },
    getDate() {
      if (this.date == '') return new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'short'})
      return new Date(this.date).toLocaleDateString(undefined, { year: 'numeric', month: 'short',  }) 
    },
    getDateCard() {
      if (this.date == '') return 'Select a date'
      return new Date(this.date).toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric' })
    },
    confirmationTitle() {
      if (this.deletion) return `Delete slot ${this.currentSlot.slot_time} ?`
      return `Update slot ?`
    },
    slotTitle() {
      if (this.create) return `Add new slot`
      return `Update slot`
    }
  },
  watch: {
    date(val) {
      this.fetchDaySlots(val)
    }
  }
}
</script>

<style lang="scss">
.primary-border {
  border: 1px solid #8C9B5A;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
.v-btn--fab.v-size--small, .v-btn--fab.v-size--default {
  height: 30px;
  width: 30px;
}
.width-200 {
  width: 200%;
}
</style>